import Grid from "@mui/material/Grid/Grid";
import {CustomLabelHeaderSmall, CustomTextLabel} from "../label";
import {CustomTextFieldWhite} from "../text";


const TextContainer = ({label, onChange, value, textKey,errorText}) => {


    return (
        <Grid container >
            <Grid style={{
                width: "100%",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: 10,
                padding: "2px 8px",
                border: "1px solid #85838363"
            }}>
                <Grid contaienr direction={"column"}>
                    <Grid item>
                        <CustomTextLabel text={label} color={"black"}
                                         fontWeight={"bold"}/>
                    </Grid>
                    <Grid item style={{marginTop: -5}}>
                        <CustomTextFieldWhite value={value} textKey={textKey} onChange={onChange}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <CustomLabelHeaderSmall
                    text={errorText}
                    color={"red"}
                    textAlign={"center"}
                    fontWeight={"bold"}/>

            </Grid>
        </Grid>
    )
}

export default TextContainer;
