import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import React from "react";



const CustomLabelHeaderLarge = (props) => {
    return (
        <Typography
            sx={{
                fontSize: {xs:"18px",md:"22px",lg:"32px"},
                color: props.color || "#FFFFFF",
                fontFamily: 'SF UI Text Bold',
                textAlign:props.textAlign,
                fontWeight:props.fontWeight,
                lineHeight:props.lineHeight
            }}>
            {props.text}
        </Typography>
    )
}


const CustomLabelHeaderMedium = (props) => {
    return (
        <Typography
            sx={{
                fontSize: {xs:"15px",md:"18px",lg:"18px"},
                color: props.color || "#FFFFFF",
                fontFamily: 'SF UI Text Bold',
                textAlign:props.textAlign,
                fontWeight:props.fontWeight,
                lineHeight:props.lineHeight
            }}>
            {props.text}
        </Typography>
    )
}


const CustomLabelHeaderSmall = (props) => {
    return (
        <Typography
            sx={{
                fontSize: {xs:"12px",md:"12px",lg:"12px"},
                color: props.color || "#FFFFFF",
                fontFamily: 'SF UI Text Bold',
                textAlign:props.textAlign,
                fontWeight:props.fontWeight,
                lineHeight:props.lineHeight,
            }}>
            {props.text}
        </Typography>
    )
}

const CustomTextLabel = (props) => {
    return (
        <Typography
            sx={{
                fontSize: {xs:"12px",md:"12px",lg:"12px"},
                color: props.color || "#FFFFFF",
                fontFamily: 'Roboto-Bold',
                textAlign:props.textAlign,
                fontWeight:props.fontWeight,
                lineHeight:props.lineHeight
            }}>
            {props.text}
        </Typography>
    )
}


export {
    CustomLabelHeaderLarge,CustomTextLabel,CustomLabelHeaderMedium,CustomLabelHeaderSmall
}
