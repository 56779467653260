import logo from './logo.svg';
import './App.css';
import LogoImage from './assets/images/logo.jpg';
import Grid from "@mui/material/Grid/Grid";
import Divider from "@mui/material/Divider/Divider";
import {CustomLabelHeaderLarge, CustomLabelHeaderMedium, CustomLabelHeaderSmall, CustomTextLabel} from "./common/label";
import Paper from "@mui/material/Paper/Paper";
import {CustomTextField, CustomTextFieldWhite} from "./common/text";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import TextContainer from "./common/text-container";
import {useEffect, useState} from "react";
import CustomFieldContainer from "./common/custom-field-container";
import CountryCodeSelector from "./common/country-codes/contry-code-selector";
import {CustomButtonLarge} from "./common/button";
import React from "react";
import SuccessModel from "./common/models/success";
import Loader from "./common/Loader";
import axios, * as others from 'axios';

function App() {

    const initialStateData = {first_name: "", last_name: "", email: "", phone_number: "", countryCode: "+1"}
    const initialStateError = {first_name: "", last_name: "", email: "", phone_number: ""};

    const [data, setData] = useState(initialStateData);
    const [count, setCount] = useState(0)
    const [error, setError] = useState(initialStateError);
    const [showModel, setShowModel] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [isSubmitPressed, setIsSubmitPressed] = useState(false);


    useEffect(() => {
        if ((window.location.href).includes("download")) {
            downloadSheetFromLink()
        }
    }, [])

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 450,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    });


    const onChange = (key, value) => {
        if (key === "phone_number" && value.length > 10)
            return;

        if(key==="phone_number")
            value=  value.replace(/\D/g, "");


        setData({...data, [key]: value});
        setCount(count + 1);
        if (isSubmitPressed) validateInput()
    }


    const closeModel = (e) => {
        setData(initialStateData);
        setError(initialStateError);
        setShowModel(false);
        setCount(count);
        setIsSubmitPressed(false);
    }


    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };


    const validateInput = () => {
        let isError = false
        const tempError = {}
        if (data.first_name.length === 0) {
            tempError["first_name"] = "Field cant be empty";
            isError = true;
        } else {
            tempError["first_name"] = "";
        }

        if (data.last_name.length === 0) {
            tempError["last_name"] = "Field cant be empty"
            isError = true;

        } else {
            tempError["last_name"] = "";
        }


        if (data.email.length === 0) {
            tempError["email"] = "Field cant be empty"
            isError = true;

        } else {
            if (validateEmail(data.email)) {
                tempError["email"] = "";
            } else {
                tempError["email"] = "Please enter valid email address"
                isError = true;

            }
        }


        if (data.phone_number.length < 10) {
            tempError["phone_number"] = "Phone number must have 10 characters."
            isError = true;

        } else {
            tempError["phone_number"] = "";
        }


        setError(tempError);
        setCount(count + 1);
        return isError;
    }


    const onSubmit = async (e) => {
        setIsSubmitPressed(true)
        const isError = validateInput();
        if (isError) return;

        const saveData = JSON.parse(JSON.stringify(data));
        saveData.phone_number = saveData.countryCode + saveData.phone_number
        await postData(saveData);
    }


    const downloadSheetFromLink=()=>{
        const a = document.createElement("a");
        a.href=("https://kosherdeli-923a5ffa6ccc.herokuapp.com/download");
        a.target="_blink";
        a.click();

    }

    const downloadSheet = async () => {
        try {
            setShowLoader(true)
            await axios.get('https://kosherdeli-923a5ffa6ccc.herokuapp.com/authorize');

            const response = await axios.get('https://kosherdeli-923a5ffa6ccc.herokuapp.com/download');
            //setShowModel(true);
            setShowLoader(false);
            console.log(response.data)
            const a = document.createElement("a");
            const blobFile = new Blob([response?.data], {type: 'text/plain'});
            const url = window.URL.createObjectURL(blobFile);
            a.href = url;
            a.download = Date.now() + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

        } catch (e) {
            console.log(e);
            setShowLoader(false);
        }
    }


    const postData = async (data) => {
        try {
            setShowLoader(true)
            const reponse = await axios.post('https://kosherdeli-923a5ffa6ccc.herokuapp.com/data', data, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Content-Type": "application/json; charset=utf-8",
                },
            });
            setShowModel(true);
            setShowLoader(false)
        } catch (e) {
            console.log(e);
            setShowLoader(false);
        }
    }


    console.log(window.location.href
    );

    return (
        <ThemeProvider theme={theme}>

            {showLoader &&
            <Loader/>
            }

            {showModel &&
            <SuccessModel onClose={closeModel}/>
            }
            <Grid container direction={"row"} justifyContent={"space-between"}
                  style={{width: "100vw", height: "calc(100vh - 4px)", maxWidth: "100%"}}>
                <Grid item xs={0} md={5.9} sx={{display: {xs: "none", md: "block", height: "100%"}}}>
                    <img src={LogoImage} style={{width: "100%", maxHeight: "100%"}}/>
                </Grid>
                <Divider orientation="vertical" flexItem/>
                <Grid item xs={12} md={6} container justifyContent={"center"} style={{height: "100%"}}>
                    <div style={{height: "100%", width: "100%"}}>
                        <Grid container style={{width: "100%", height: "100%"}} alignItems={"center"}
                              justifyContent={"center"}>
                            <Grid item direction={"row"} container sx={{display: {xs: "auto", md: "none"}}}
                                  alignItems={"Center"}
                                  justifyContent={"center"}>
                                <Grid item>
                                    <img src={LogoImage} style={{width: "124px", height: "124px"}}/>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}
                                  item xs={12} style={{background: "transparent"}}>
                                <Grid item>
                                    <CustomLabelHeaderLarge text={"Customer Engagement Form"} color={"black"}
                                                            fontWeight={"bold"}/>
                                    <Divider style={{width: "100%", height: "2.4px", background: "#730220"}}/>
                                </Grid>
                                <Grid item style={{marginTop: "40px"}} container xs={10} md={10} lg={7}>
                                    <Grid item xs={12} container>
                                        <CustomLabelHeaderMedium text={"Basic Info"} color={"black"}
                                                                 fontWeight={"bold"}/>

                                    </Grid>
                                    <Grid item xs={12} container justifyContent={"space-between"}
                                          style={{marginTop: "20px"}}>
                                        <Grid item xs={5.5}>
                                            <TextContainer onChange={onChange}
                                                           textKey={"first_name"}
                                                           label={"First Name"}
                                                           errorText={error["first_name"]}
                                                           value={data.first_name}/>
                                        </Grid>
                                        <Grid item xs={5.5}>
                                            <TextContainer onChange={onChange}
                                                           textKey={"last_name"}
                                                           label={"Last Name"}
                                                           errorText={error["last_name"]}
                                                           value={data.last_name}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{marginTop: "15px"}}>
                                        <Grid item xs={12}>
                                            <TextContainer onChange={onChange}
                                                           errorText={error["email"]}
                                                           textKey={"email"} label={"Email"}
                                                           value={data.email}/>

                                        </Grid>
                                    </Grid>
                                    <Grid container style={{marginTop: "15px"}}>
                                        <Grid item xs={12}>
                                            <CustomFieldContainer onChange={onChange} textKey={"phone_number"}
                                                                  label={"Mobile Number"}
                                                                  errorText={error["phone_number"]}
                                                                  value={data.phone_number}
                                                                  startAdornment={<CountryCodeSelector
                                                                      countryCode={data.countryCode}
                                                                      setCountryCode={(code) => {
                                                                          setData({...data, countryCode: code})
                                                                          setCount(count + 1);
                                                                      }}
                                                                  />}
                                                                  endAdornment={"$"}/>

                                        </Grid>
                                    </Grid>

                                    <Grid container style={{marginTop: "15px"}} justifyContent={"center"}>
                                        <Grid container item xs={10}>
                                            <CustomLabelHeaderSmall
                                                text={"By submitting your information you are agreeing to receive promotional and emails and WhatsApp message. Your information will never be sold or transferred."}
                                                color={"black"}

                                                textAlign={"center"}
                                                fontWeight={"bold"}/>

                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent={"center"}
                                          style={{marginTop: 40, marginBottom: "20px"}}>
                                        <Grid item xs={8} onClick={onSubmit}>
                                            <CustomButtonLarge text={"Submit"} background={"black"}/>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
