import Button from "@mui/material/Button/Button";
import Typography from "@mui/material/Typography/Typography";
import Grid from "@mui/material/Grid/Grid";
import React from "react";


const CustomButtonLarge = (props) => {
    return (
        <Button
            sx={{padding:{xs:"10px 0px",md:"10px 0px"}}}
            disabled={props.disabled}
            style={{
                background: props.background || '#4E20EA', width: "100%", opacity: props.disabled ? 0.8 : 1,
                borderRadius: 15, fontSize: 13, fontWeight: 'bold',borderColor:props.borderColor,
                fontFamily: 'SF UI Text Bold', textTransform: 'none',border:props.border
            }}
            variant="contained"

        >
            <Typography
                sx={{
                    fontSize: {xs: "12px",sm:"15px",md:"17px"},
                }}
                style={{
                    color: props.color || 'white',
                    fontFamily: 'SF UI Text Bold',
                }}>
                {props.text}
            </Typography></Button>)
}


const CustomButtonSmall = (props) => {
    return (
        <Button
            sx={{padding:{xs:"10px 15px 10px 15px",md:"10px 35px 10px 35px",opacity:props.opacity}}}
            disabled={props.disabled}
            style={{
                background: props.background || '#4E20EA', width: "100%", opacity: props.disabled ? 0.8 : props.opacity || 1,
                borderRadius: 20, fontSize: 13, fontWeight: 'bold',borderColor:props.borderColor,
                fontFamily: 'SF UI Text Bold', textTransform: 'none',border:props.border
            }}
            variant="contained"

        >
            <Typography
                sx={{
                    fontSize: {xs: "12px",sm:"15px",md:"17px"},
                }}
                style={{
                    color: props.color || 'white',
                    fontFamily: 'SF UI Text Bold',
                }}>
                {props.text}
            </Typography></Button>)
}



const CustomButtonExtraSmall = (props) => {
    return (
        <Button
            sx={{padding:{xs:"5px 5px 5px 5px",md:"5px 15px 5px 15px"}}}
            disabled={props.disabled}
            style={{
                background: props.background || '#4E20EA', width: "100%", opacity: props.disabled ? 0.8 : 1,
                borderRadius: 20, fontSize: 13, fontWeight: 'bold',borderColor:props.borderColor,
                fontFamily: 'SF UI Text Bold', textTransform: 'none',border:props.border
            }}
            variant="contained"

        >
            <Typography
                sx={{
                    fontSize: {xs: "12px",sm:"12px",md:"15px"},
                }}
                style={{
                    color: props.color || 'white',
                    fontFamily: 'SF UI Text Bold',
                }}>
                {props.text}
            </Typography></Button>)
}


export {CustomButtonLarge,CustomButtonSmall,CustomButtonExtraSmall}
