import React from "react";
import {makeStyles} from "@mui/styles";
import TextField from "@mui/material/TextField/TextField";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import MenuItem from "@mui/material/MenuItem/MenuItem";


const useStyles = makeStyles((theme) => ({

    root: {
        color:"white",
        "& .MuiInputBase-root": {
            background: "transparent",

        },

        "& .MuiInputLabel-standard": {
            color: "white",
            opacity: "50%",
            fontSize:"18px"
        },
    },
    rootBlack: {
        color:"white",
        "& .MuiInputBase-root": {
            background: "transparent",

        },

        "& .MuiInputLabel-standard": {
            color: "black",
            opacity: "100%",
            fontSize:"18px"
        },
    },
    rootWhite: {
        color:"black",
        "& .MuiInputBase-root": {
            background: "transparent",

        },

        "& .MuiInputLabel-standard": {
            color: "black",
            fontSize:"18px"
        },
    },
    rootWhiteFilled: {
        color:"black",
        "& .MuiInputBase-root": {
            background: "transparent",

        },

        "& .MuiInputLabel-standard": {
            color: "black",
            fontSize:"18px"
        },
    },
    underline: {
        '&:after': {
            borderBottom: `1.5px solid #424242`,
        },
        '&:before': {
            borderBottom: `1.5px solid #424242`,
        },
        '&:hover': {
            borderBottom: `1px #424242`,
        },
        '&:focused': {
            borderBottom: `1px solid #424242`,
        },

    },
    withoutUnderline: {
        '&:after': {
            borderBottom: `0px solid #424242`,
        },
        '&:before': {
            borderBottom: `0px solid #424242`,
        },
        '&:hover': {
            borderBottom: `0px #424242`,
        },
        '&:focused': {
            borderBottom: `0px solid #424242`,
        },

    },
    rootSearch: {
        "& .MuiInputBase-root": {
            [theme.breakpoints.up('xs')]: {
                height: "40px",
                borderRadius: "14px",
            },
            [theme.breakpoints.up('md')]: {
                height: "50px",
                borderRadius: "18px",
            },
            background:"#F5F5F5"
        },
        "& input::placeholder": {
            [theme.breakpoints.up('xs')]: {
                fontSize: "14px"
            },
            [theme.breakpoints.up('md')]: {
                fontSize: "18px"
            },
        }
    },
    notchedOutlineSearch: {
        borderWidth: "0.1px",
        borderColor: "#E9E9E9 !important",
    }

}));


const CustomTextField = (props) => {
    const classes = useStyles();
    const {isDark}=props;
    return (
        <TextField
            fullWidth
            variant={"standard"}
            onChange={props.onChange}
            className={isDark?classes.rootBlack:classes.root}
            label={props.label}
            value={props.value && (props.value).length > 0 ? props.value : ''}
            type={props.type}
            helperText={props.helperText}
            error={props.error}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            autoComplete='off'
            inputRef={props.params && props.params.inputRef}
            InputProps={{
                 sx:{color:isDark?"black":"white"},classes: {underline: classes.underline}, ...props.otherInputProps
            }}


        />
    )
}


const CustomTextFieldBlack = (props) => {
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            variant={"standard"}
            onChange={props.onChange}
            className={classes.rootBlack}
            label={props.label}
            value={props.value && (props.value).length > 0 ? props.value : ''}
            type={props.type}
            helperText={props.helperText}
            error={props.error}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            autoComplete='off'
            inputRef={props.params && props.params.inputRef}
            InputProps={{
                sx:{color:"black"},classes: {underline: classes.underline}, ...props.otherInputProps
            }}


        />
    )
}



const CustomTextFieldMultilineBlack = (props) => {
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            variant={"standard"}
            onChange={props.onChange}
            className={classes.rootBlack}
            label={props.label}
            value={props.value && (props.value).length > 0 ? props.value : ''}
            type={props.type}
            helperText={props.helperText}
            error={props.error}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            autoComplete='off'
            multiline
            rows={4}
            inputRef={props.params && props.params.inputRef}
            InputProps={{
                sx:{color:"black"},classes: {underline: classes.underline}, ...props.otherInputProps
            }}


        />
    )
}

const CustomTextFieldWhite = (props) => {
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            variant={"standard"}
            onChange={(e)=>props.onChange(props.textKey,e.target.value)}
            className={classes.rootWhite}
            label={props.label}
            value={props.value && (props.value).length > 0 ? props.value : ''}
            type={props.type}
            helperText={props.helperText}
            error={props.error}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            autoComplete='off'
            inputRef={props.params && props.params.inputRef}
            InputProps={{
                sx:{color:"black"},disableUnderline: true,classes: {underline: classes.withoutUnderline}, ...props.otherInputProps
            }}


        />
    )
}


const CustomTextFieldWhiteWithList = (props) => {
    const classes = useStyles();

    console.log(props.list)
    return (
        <TextField
            id="standard-select-currency-native"
            select
            className={classes.rootWhite}
            value={props.value}
            variant="standard"
            InputProps={{
                sx:{color:"black"},disableUnderline: true,classes: {underline: classes.withoutUnderline}, ...props.otherInputProps
            }}

        >
            {(props.list).map((option) => (
                <MenuItem style={{width:"120px"}} key={option.value} value={option.value} onClick={(e)=>
                    props.onSelect({value:option.value,label:option.label})}>

                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}


const CustomTextFieldWhiteWithIcons = (props) => {
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            variant={"standard"}
            onChange={(e)=>props.onChange(props.textKey,e.target.value)}
            className={classes.rootWhite}
            label={props.label}
            value={props.value && (props.value).length > 0 ? props.value : ''}
            type={props.type}
            helperText={props.helperText}
            error={props.error}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            autoComplete='off'
            inputRef={props.params && props.params.inputRef}
            InputProps={{
                sx:{color:"black"},disableUnderline: true,classes: {underline: classes.withoutUnderline}, ...props.otherInputProps,
                startAdornment: props.startAdornment,
                endAdornment: props.endAdornment

            }}


        />
    )
}



const CustomTextFilled = (props) => {
    const classes = useStyles();

    return (
        <TextField
            fullWidth
            variant={"standard"}
            onChange={props.onChange}
            className={classes.rootWhiteFilled}
            label={props.label}
            value={props.value && (props.value).length > 0 ? props.value : ''}
            type={props.type}
            helperText={props.helperText}
            error={props.error}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            placeholder={props.placeholder}
            autoComplete='off'
            inputRef={props.params && props.params.inputRef}
            InputProps={{
                sx:{color:"black",background:"white"},disableUnderline: true,classes: {underline: classes.withoutUnderline}, ...props.otherInputProps
            }}


        />
    )
}



const CustomSearchField = (props) => {
    const classes = useStyles();
    return (
        <TextField
            fullWidth
            onChange={props.onChange}
            className={classes.rootSearch}
            value={props.value}
            type={props.type}
            placeholder={props.placeholder}
            helperText={props.helperText}
            error={props.error}
            InputProps={{
                sx: {height: 40}, classes: {notchedOutline: classes.notchedOutlineSearch}, ...props.otherInputProps
            }}

        />
    )
}

export {CustomTextField,CustomTextFieldWhite,CustomTextFilled,CustomSearchField,CustomTextFieldBlack,CustomTextFieldMultilineBlack,
    CustomTextFieldWhiteWithIcons,CustomTextFieldWhiteWithList};
