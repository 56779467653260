import Modal from "@mui/material/Modal/Modal";
import Grid from "@mui/material/Grid/Grid";
import Lottie from 'react-lottie';
import SuccessAnimation from 'src/assets/json/success';
import React from "react";
import {CustomLabelHeaderLarge, CustomLabelHeaderMedium} from "../../label";
import Paper from "@mui/material/Paper/Paper";
import {CustomButtonLarge} from "../../button";

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: SuccessAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: 5,
    pt: 2,
    px: 4,
    pb: 3,
};
const SuccessModel = ({onClose}) => {


    return (
        <Paper>
            <Grid container alignItems="center" direction={"column"}
                  style={{background: "white", border: "0px solid red", zIndex: 1}}
                  sx={{...style, width: 400}}>
                <Grid item>
                    <CustomLabelHeaderLarge text={"Success"} color={"black"}
                                            fontWeight={"bold"}/>

                </Grid>
                <Grid item style={{marginTop:20}}>
                    <Lottie options={defaultOptions}
                            height={50}
                            width={50}
                    />
                </Grid>
                <Grid item style={{marginTop:20}}>
                    <CustomLabelHeaderMedium text={"Thanks for filling the form."} color={"black"}
                                            fontWeight={"bold"}/>

                </Grid>

                <Grid container justifyContent={"center"} style={{marginTop:50,marginBottom:"20px"}}>
                    <Grid item xs={8} onClick={onClose}>
                        <CustomButtonLarge text={"Continue"} background={"black"}/>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )

}


export default SuccessModel;
