import React, {useState, useMemo} from 'react'
import Select from 'react-select'
import CountryCodesList from 'src/assets/json/country-codes';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField/TextField";
import {CustomTextFieldWhiteWithList} from "../text";
import Grid from "@mui/material/Grid/Grid";


function CountrySelector({countryCode,setCountryCode}) {
    const countryList = CountryCodesList.map((country) => ({label: country.dial_code, value: country.name}))
    const [value, setValue] = useState('United States')
    const options = useMemo(() => countryList, [])

    const changeHandler = ({value,label}) => {
        console.log(value)
        setValue(value)
        setCountryCode(label)
    }

    const customStyle = {
        option: (base, state) => ({
            ...base,
            fontSize: 10,
            backgroundColor: state.isSelected ? "red" : "yellow",
        })
    }

    return (
        <Grid item>
        <CustomTextFieldWhiteWithList value={value} list={countryList} onSelect={changeHandler}>
        </CustomTextFieldWhiteWithList>
        </Grid>
    )
}

export default CountrySelector
