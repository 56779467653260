import Grid from "@mui/material/Grid/Grid";
import {CustomLabelHeaderSmall, CustomTextLabel} from "../label";
import {CustomTextFieldWhite, CustomTextFieldWhiteWithIcons} from "../text";
import WhatsappIcon from 'src/assets/images/whatsapp.png';

const CustomFieldContainer = ({label, onChange, value, textKey, startAdornment,errorText, endAdornment,countryCode}) => {


    return (
        <Grid container>
            <Grid style={{
                width: "100%",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                borderRadius: 10,
                padding: "2px 8px",
                border: "1px solid #85838363"
            }}>
                <Grid container direction={"row"}>
                    <Grid item xs={10} container direction={"column"}>
                        <Grid item>
                            <CustomTextLabel text={label} color={"black"}
                                             fontWeight={"bold"}/>
                        </Grid>
                        <Grid item style={{marginTop: 0}}>
                            <CustomTextFieldWhiteWithIcons value={value} onChange={onChange}
                                                           textKey={textKey}
                                                           startAdornment={startAdornment}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={2} container justifyContent={"flex-end"} alignItems={"center"}>
                        <img style={{width: "22px", height: "22px"}} src={WhatsappIcon}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <CustomLabelHeaderSmall
                    text={errorText}
                    color={"red"}
                    textAlign={"center"}
                    fontWeight={"bold"}/>

            </Grid>
        </Grid>
    )
}

export default CustomFieldContainer;
